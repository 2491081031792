<script>
import {UBtn, UField, UInput, UAjaxBar, UCheckbox} from 'uloc-vue'
import LoginMixin from '../login.mixin.js'
import Validacao2Fa from "components/auth/Validacao2Fa.vue"
import LoginHeader from "./LoginHeader.vue";
import LoginFooter from "./LoginFooter.vue";

export default {
  name: 'Auth',
  inject: ['appRoot'],
  mixins: [LoginMixin],
  components: {
    Validacao2Fa,
    UAjaxBar,
    UBtn,
    //UCheckbox,
    UField,
    UInput,
    LoginHeader,
    LoginFooter
  },
  props: {},
  data() {
    return {
      loginErrorData: null,
      isSubmiting: false,
      need2FA: false,
      bg: ''
    }
  },
  beforeCreate() {
    this.lang = this.$uloc ? this.$uloc.i18n.lang : 'pt-BR' // TODO: Desenvolver próprio sistema de línguas do Auth
  },
  mounted() {
    this.getBG()
  },
  beforeDestroy() {
  },
  methods: {
    __doLogin() {
      this.$refs.bar && this.$refs.bar.start()
      this.isSubmiting = true
      this.doLogin()
          .then((r) => {
            this.$refs.bar && this.$refs.bar.stop()
            if (r && r.need2FA) {
              this.need2FA = r
              this.isSubmiting = false
              return
            }
            if (this.$route.query.redirect && this.$route.query.redirect !== '/logout') {
              this.$router.push({path: this.$route.query.redirect})
            } else {
              this.$router.push('/')
            }
            this.isSubmiting = false
          })
          .catch((err) => {
            this.isSubmiting = false
            console.log('ERRO LOGIN')
            console.log('Login error', err)
            this.loginErrorData = err.message || 'Ocorreu algum erro no login'
            this.$refs.bar && this.$refs.bar.stop()
            this.$uloc.notify({
              type: 'negative',
              message: this.loginErrorData
            })
          })
    },
    getBG() {
      if (appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('empresa.loginbg')) {
        this.bg = `background-image: url('${$appConfig.getConfig("empresa.loginbg")}');`;
      }

      this.bg = '';
    }
  },
  computed: {
    isLogged: function () {
      return this.$uloc ? this.$uloc.auth.logged : this.$auth.logged
    }
  }
}
</script>

<template>
  <div class="sl-auth" v-if="!isLogged"
      :style="this.bg">
    <div class="sl-login animated">
      <login-header/>
      <div class="center-content">
        <div class="login-form">
          <div v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('erp.login.logo') && $appConfig.getConfig('erp.login.logo') && $appConfig.getConfig('erp.login.logo')" class="login-header">
            <div class="lh-logo full">
              <img :src="$appConfig && $appConfig.getConfig('erp.login.logo')" />
            </div>
          </div>
          <div v-else-if="appRoot.installed && $appConfig" class="login-header">
            <div v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('empresa.license') && $appConfig.getConfig('empresa.license') === 'enterprise' && $appConfig.isConfigNotEmpty('empresa.logomarca')" class="lh-logo full">
              <a target="_blank"><img :src="$appConfig.getConfig('empresa.logomarca')" /></a>
            </div>
            <div v-else class="lh-logo">
              <a href="https://e-leiloes.com.br" target="_blank"><img src="/logo-eleiloes.svg" /></a>
            </div>
            <div class="lh-poslogo" v-if="appRoot.installed && $appConfig && $appConfig.isConfigNotEmpty('empresa.license') && $appConfig.getConfig('empresa.license') !== 'enterprise'">Não tem acesso? <a target="_blank" href="https://www.suporteleiloes.com.br/atendimento">Clique aqui</a></div>
          </div>
          <form v-if="need2FA === false" class="auth-form" method="post" @submit.prevent="__doLogin">
  <!--          <div class="m-t sl-alert sl-alert-warning bg-yellow-2">Informamos que estamos concluindo a migração do software para a versão 3.0.0, dentro de 24h terminaremos nossos trabalhos e esta mensagem desaparecerá.</div>-->
            <div class="block m-t-lg"></div>
            <div class="login-form-pre-header">
              <div class="login-lbl">Conectar ao <strong>ERP</strong></div>
              <div class="login-lbl-version" v-if="$globalConfig && $globalConfig.appVersion">v{{ $globalConfig.appVersion }}</div>
              <div class="login-lbl-version" v-else>&nbsp;</div>
            </div>
            <u-field
                :label-width="12"
                class="login-input"
                label="CPF, Login ou e-mail"
            >
              <i class="fa fal fa-user form-icon-float" />
              <u-input required type="text" v-model="user.email" autofocus class="login-input" hide-underline/>
            </u-field>
            <u-field
                :label-width="12"
                label="Senha de acesso"
                class="login-input m-t-md"
            >
              <i class="fa fal fa-key form-icon-float" />
              <u-input required type="password" v-model="user.password" class="login-input" hide-underline/>
            </u-field>

            <u-btn :disabled="isSubmiting" type="submit" size="md"
                    label="Entrar"
                    class="w-full m-t-md btn-login" no-caps />
            <div class="m-t-md text-center">
              <a class="cursor-pointer login-recuperar-senha">Esqueci minha senha</a>
            </div>
          </form>
          <validacao2-fa :auth="need2FA" v-else />
          <login-footer/>
        </div>
      </div>
    </div>
    <u-ajax-bar ref="bar" color="primary"/>
  </div>
</template>

<style lang="stylus" src="../../../../../assets/style.styl">

</style>